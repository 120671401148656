import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppNavigationService } from '@app/layout/nav/app-navigation.service';
import { BWJoinCommonModule } from '@shared/common/common.module';
import { AbpModalFooterComponent } from '@shared/components/modal/abp-modal-footer.component';
import { AbpModalHeaderComponent } from '@shared/components/modal/abp-modal-header.component';
import { AbpValidationSummaryComponent } from '@shared/components/validation/abp-validation.summary.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { AppAuthService } from '../../shared/common/auth/app-auth.service';
import { AppRouteGuard } from '../../shared/common/auth/auth-route-guard';

@NgModule({
    imports: [
        CommonModule,
        BWJoinCommonModule,
        UtilsModule,
    ],
    declarations: [
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
    ],
    exports: [
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
    ],
    providers: [
        AppNavigationService,
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
