import { AppConsts } from '@shared/AppConsts';
import { StyleLoaderService } from '@shared/utils/style-loader.service';

export class DynamicResourcesHelper {

    static loadResources(callback: () => void): void {
        Promise.all([DynamicResourcesHelper.loadStyles()])
            .then(() => {
                callback();
            });
    }

    static loadStyles(): Promise<any> {
        const styleLoaderService = new StyleLoaderService();

        let styleUrls = [
            //AppConsts.appBaseUrl + '/assets/primeng/datatable/css/primeng.datatable.min.css',
        ].concat(DynamicResourcesHelper.getAdditionalThemeAssets());

        styleLoaderService.loadArray(styleUrls);

        return Promise.resolve(true);
    }

    static getAdditionalThemeAssets(): string[] {
            return [];
    }
}
