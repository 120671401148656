import { PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as localForage from 'localforage';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { AppPreBootstrap } from '@shared/AppPreBootstrap';
import { BWJoinCommonModule } from '@shared/common/common.module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { CookieConsentService } from '@shared/common/session/cookie-consent.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { LocaleMappingService } from '@shared/locale-mapping.service';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { AppCommonModule } from '@app/shared/common/app-common.module';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgSelectModule } from '@ng-select/ng-select';

export function appInitializerFactory(injector: Injector, platformLocation: PlatformLocation) {
  return () => {
      let spinnerService = injector.get(NgxSpinnerService);

      spinnerService.show();

      return new Promise<boolean>((resolve, reject) => {
          AppConsts.appBaseHref = getBaseHref(platformLocation);
          let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

          initializeLocalForage();

          AppPreBootstrap.run(appBaseUrl, () => {

              handleLogoutRequest(injector.get(AppAuthService));
              initializeLocalForage();

              let appSessionService: AppSessionService = injector.get(AppSessionService);
              appSessionService.init().then((result) => {
                  initializeCookieConsent(injector);
                  registerLocales(resolve, reject, spinnerService);
              }, (err) => {
                  spinnerService.hide();
                  reject(err);
              });
          }, resolve, reject);
      });
  };
}

function initializeLocalForage() {
  localForage.config({
      driver: localForage.LOCALSTORAGE,
      name: 'BW Join',
      version: 1.0,
      storeName: 'bw_relocation_local_storage',
      description: 'Cached data for BW Join'
  });
}

function initializeCookieConsent(injector: Injector) {
  let cookieConsentService: CookieConsentService = injector.get(CookieConsentService);
  cookieConsentService.init();
}

function getDocumentOrigin() {
  if (!document.location.origin) {
      return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
  }

  return document.location.origin;
}

function registerLocales(resolve: (value?: boolean | Promise<boolean>) => void, reject: any, spinnerService: NgxSpinnerService) {
  if (shouldLoadLocale()) {
    let angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
      import(`/node_modules/@angular/common/locales/${angularLocale}.mjs`)
          .then(module => {
            registerLocaleData(module.default);
              NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales().then(_ => {
                  resolve(true);
                  spinnerService.hide();
              });
          }, reject);
  } else {
      NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales().then(_ => {
          resolve(true);
          spinnerService.hide();
      });
  }
}

export function shouldLoadLocale(): boolean {
  return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== 'en-US';
}

export function convertAbpLocaleToAngularLocale(locale: string): string {
  return new LocaleMappingService().map('angular', locale);
}

export function getRemoteServiceBaseUrl(): string {
  return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(): string {
  return convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
}

// export function getCurrencyCode(injector: Injector): string {
//   let appSessionService: AppSessionService = injector.get(AppSessionService);
//   return appSessionService.application.currency;
// }

export function getBaseHref(platformLocation: PlatformLocation): string {
  let baseUrl = platformLocation.getBaseHrefFromDOM();
  if (baseUrl) {
      return baseUrl;
  }

  return '/';
}

function handleLogoutRequest(authService: AppAuthService) {
  let currentUrl = UrlHelper.initialUrl;
  let returnUrl = UrlHelper.getReturnUrl();
  if (currentUrl.indexOf(('account/logout')) >= 0 && returnUrl) {
      authService.logout(true, returnUrl);
  }
}



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCommonModule.forRoot(),
    BWJoinCommonModule.forRoot(),
    ServiceProxyModule,
    HttpClientModule,
    RootRoutingModule,
    NgxSpinnerModule,
    // NgbModule
    //NgSelectModule
  ],
  declarations: [
    RootComponent,
  ],
  providers: [
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [Injector, PlatformLocation],
        multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLanguage,
    },
  ],
  bootstrap: [RootComponent],
})
export class RootModule {}
