import { MenuItem } from "@shared/layout/menu-item";

export class AppNavigationService {
    public getMenuItems(): MenuItem[] {
      return [
        new MenuItem(
          'Relocations',
          '/app/relocations',
          'fas fa-users',
          'Pages.Relocations'
        ),
        new MenuItem(
          'Companies',
          '/app/companies',
          'fas fa-users',
          'Pages.Companies'
        ),
        new MenuItem(
          'Users',
          '/app/users',
          'fas fa-users',
          'Pages.Users'
        ),
        // // new MenuItem(
        // //   'Roles',
        // //   '/app/roles',
        // //   'fas fa-theater-masks',
        // //   'Pages.Roles'
        // // )
      ];
  }
}
