import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { CookieConsentService } from './session/cookie-consent.service';
import { AppLocalizationService } from './localization/app-localization.service';

@NgModule({
    imports: [
        CommonModule
    ]
})
export class BWJoinCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [
                AppSessionService,
                CookieConsentService,
                CookieConsentService,
                AppLocalizationService,
                AppUrlService,
                LayoutStoreService
            ]
        };
    }
}
