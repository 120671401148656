import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleplaceDirective } from '@app/shared/directives/googleplace.directive';
import { GeneralComboComponent } from '@app/shared/general-combo.component';
import { LocalizePipe } from '@shared/common/pipes/localize.pipe';
import { PermissionAnyPipe } from '@shared/common/pipes/permission-any.pipe';
import { PermissionPipe } from '@shared/common/pipes/permission.pipe';
import { ArrayToTreeConverterService } from './array-to-tree-converter.service';
import { BusyIfDirective } from './directives/busy-if.directive';
import { BusyDirective } from './directives/busy.directive';
import { ButtonBusyDirective } from './directives/button-busy.directive';
import { LocalStorageService } from './local-storage.service';
import { ScriptLoaderService } from './script-loader.service';
import { StyleLoaderService } from './style-loader.service';
import { TreeDataHelperService } from './tree-data-helper.service';
import { EqualValidator } from './validation/equal-validator.directive';
import { LuxonFormatPipe } from './luxon-format.pipe';
import { LuxonFromNowPipe } from './luxon-from-now.pipe';
import { DatePickerLuxonModifierDirective } from './date-picker-luxon-modifier.directive';
import { DateRangePickerLuxonModifierDirective } from './date-range-picker-luxon-modifier.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        LocalStorageService,
        StyleLoaderService,
        ScriptLoaderService,
        TreeDataHelperService,
        ArrayToTreeConverterService,
    ],
    declarations: [
        BusyIfDirective,
        PermissionPipe,
        PermissionAnyPipe,
        LocalizePipe,
        BusyDirective,
        ButtonBusyDirective,
        EqualValidator,
        LuxonFormatPipe,
        LuxonFromNowPipe,
        DatePickerLuxonModifierDirective,
        DateRangePickerLuxonModifierDirective,
        GeneralComboComponent,
        GoogleplaceDirective,
    ],
    exports: [
        BusyIfDirective,
        PermissionPipe,
        PermissionAnyPipe,
        LocalizePipe,
        BusyDirective,
        ButtonBusyDirective,
        EqualValidator,
        LuxonFormatPipe,
        LuxonFromNowPipe,
        DatePickerLuxonModifierDirective,
        DateRangePickerLuxonModifierDirective,
        GeneralComboComponent,
        GoogleplaceDirective,
    ]
})
export class UtilsModule { }
