import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import * as ApiServiceProxies from './service-proxies';


@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.OfficeLocationServiceProxy,
        ApiServiceProxies.PrelicensingClassServiceProxy,
        ApiServiceProxies.ContactUsServiceProxy,
        ApiServiceProxies.LeadRoutingServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
